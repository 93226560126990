/* eslint-disable react/prop-types */
import React from "react";
import classNames from  "classnames";
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';

export default class Accordion extends React.Component {
  state = {
    open: this.props.open,
  }

  toggleSection = (index) => () => {
    this.setState(({ open }) => ({
      open: index === open ? undefined : index
    }))
  }

  render() {
    return (
      <div className="accordion">
        {React.Children.map(this.props.children, (child, index) => {
          if (child.type !== AccordionItem) return null
          return React.cloneElement(child, {
            isOpen: child.props.open || this.state.open === index,
            onClick: this.toggleSection(index),
          })
        })}
      </div>
    )
  }
}

Accordion.propTypes = {
  open: PropTypes.number,
}

const AccordionItem = ({ children, isOpen, onClick, schemaOrgQuestions }) => (
  <Card {...schemaOrgQuestions}>
    {React.Children.map(children, child => {
      if (child.type === AccordionHeader) {
        return React.cloneElement(child, { onClick, isOpen })
      }

      if (child.type === AccordionBody) {
        return React.cloneElement(child, { isOpen })
      }

      return null
    })}
    <style>
      {
        `.accordion .card {
          border-radius: 12px !important;
          box-shadow: 2px 4px 16px 0 rgba(163, 177, 198, 0.5);
          padding: 0;
        }
        .accordion .card:hover {
          box-shadow: 2px 4px 16px 0 rgba(163, 177, 198, 0.5);
        }
        .accordion .card:not(:last-child) {
          margin-bottom: 8px;
        }`
      }
    </style>
  </Card>
)

const AccordionHeader = ({ children, onClick, isOpen, schemaOrgProps }) => (
  <CardHeader
    className="p-1"
    style={{
      background: isOpen? '#f2f6fd':'#ffffff',
      borderRadius: !isOpen ? '0 !important': '12px',
      padding: '4px !important',
      ...(isOpen ? { borderBottomLeftRadius: '0'} : {}),
      ...(isOpen ? { borderBottomRightRadius: '0'} : {}),
    }}
    {...schemaOrgProps}
  >
    <div
      onClick={onClick}
      className="mb-0"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        padding: '8px',
        marginTop: '0',
        fontWeight: '500'
      }}
    >
      <h3
        color="link"
        className="text-left p-0"
        style={{
          fontSize: 14,
          fontWeight: 600,
          color: "#5e5e5e",
          cursor: 'pointer',
          marginBottom: '0',
          lineHeight: '1.5rem',
          width: '100%',
        }}
      >
        {children}
      </h3>
      <i
        style={{
          padding: "1px 15px 0",
          color: "#1d66dd",
          fontWeight: "700"
        }}
        className={
          `icon ${isOpen ? "lifepal-arrow-up" : "lifepal-arrow-down"}`
        }>
      </i>
    </div>
    <style>
      {
        `.card-header:hover {
          background: #f7faff !important
        }`
      }
    </style>
  </CardHeader>
)

const AccordionBody = ({ children, isOpen, schemaOrgAnswers, schemaOrgProps }) => (
  <CardBody
    className={classNames(isOpen ? 'accordion__show' : 'accordion__hide')}
    isOpen={isOpen}
    {...schemaOrgAnswers}
    {...schemaOrgProps}
  >
    {children}
    <style>
      {
        `.accordion__show {
          visibility: visible;
          max-height: auto;
          transition: max-height 0.3s ease;
          padding: 1.25rem;
        }
        .accordion__hide {
          visibility: hidden;
          max-height: 0;
          transition: max-height 0.3s ease;
          padding: 0;
        }
        `
      }
    </style>
  </CardBody>
)

Accordion.Item = AccordionItem
Accordion.Header = AccordionHeader
Accordion.Body = AccordionBody
